<template>
    <modal ref="eliminarEtapa" titulo="Eliminar etapa" adicional="Eliminar" @adicional="eliminarEtapa">
        <div class="m-3">
           <div class="row">
               <p class="col-12 f-14 text-center">¿Está seguro que desea eliminar esta etapa?</p>
           </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            
        }
    },
    methods: {
        toggle(){
            this.$refs.eliminarEtapa.toggle()
        },
        eliminarEtapa(){
            this.$refs.eliminarEtapa.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
