<template>
    <section class="ver-cotizacion-cliente container-fluid">
        <navbar-admin titulo="Cotizaciones">
            <el-tooltip content="Editar" placement="bottom" effect="light" visible-arrow>
                <button  class="btn btn-square32-5d ml-2 cr-pointer" @click="editarCotizacion">
                    <i class="icon-pencil-outline text-white" />
                </button>
            </el-tooltip>
            <el-tooltip content="Eliminar" placement="bottom" effect="light" visible-arrow>
                <button class="btn btn-square32-5d ml-2 cr-pointer" @click="eliminarCotizacion">
                    <i class="icon-trash-can-outline text-white" />
                </button>
            </el-tooltip>
        </navbar-admin>
        <titulo-divisor titulo="Válvula 5214 2000" />
        <div class="row justify-content-center">
            <div class="col-12 col-lg-9">
                <div class="shadow29 br-10 pb-2">
                    <!-- info top -->
                    <div class="row bg-general text-white br-t-10 f-13 f-500 py-3 mx-0">
                        <div class="col-3 text-center">
                            <p>Cotización: 2351</p>
                        </div>
                        <div class="col-3 text-center">
                            <p>Fecha: 26/08/2022</p>
                        </div>
                        <div class="col-3 text-center">
                            <p>Oportunidad: Nestlé</p>
                        </div>
                        <div class="col-3 text-center">
                            <p>País: Mexico</p>
                        </div>
                    </div>
                    <!-- porcentajes -->
                    <div class="row my-4 mx-0 justify-content-center">
                        <div class="col-4 d-flex">
                            <p class="f-12 mr-2 my-auto">Porcentaje de utilidad país:</p>
                            <div class="border bg-f6 f-12 text-5d br-3 w-75px text-center d-middle-center">
                                10%
                            </div>
                        </div>
                        <div class="col-4 d-flex">
                            <p class="f-12 mr-2 my-auto">Porcentaje de utilidad general</p>
                            <div class="border bg-f6 f-12 text-5d br-3 w-75px text-center d-middle-center">
                                10%
                            </div>
                        </div>
                        <div class="col-auto d-flex icon-option">
                            <el-popover v-model="visibleEditarPorcentaje" placement="bottom-end" width="197" trigger="click" class="px-0">
                                <div class="row mx-0 justify-content-center f-600 text-general f-12 text-center">
                                    Editar porcentaje de utilidad general
                                </div>
                                <hr class="my-2" />
                                <ValidationObserver ref="validatorPorcentajeUtilidad">
                                    <div class="row mx-0 justify-content-center my-2">
                                        <div class="col-10 my-2">
                                            <p class="input-label-top">Valor del porcentaje</p>
                                            <ValidationProvider v-slot="{errors}" name="valor" rules="required">
                                                <el-input v-model="valorPorcentajeGeneral" size="small" placeholder="%" />
                                                <vee-error :text="errors[0]" />
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </ValidationObserver>
                                <hr />
                                <div class="text-right m-0">
                                    <button class="btn btn-cerrar f-12" @click="visibleEditarPorcentaje = !visibleEditarPorcentaje">Cerrar</button>
                                    <button class="btn btn-crear f-12 ml-2" @click="handleEditarPorcentaje">Agregar</button>
                                </div>
                                <el-tooltip slot="reference" content="Editar utilidad" placement="bottom" effect="light" visible-arrow>
                                    <i class="icon-pencil f-18 cr-pointer" />
                                </el-tooltip>
                            </el-popover>
                            <el-tooltip content="Historial cambios" placement="right" effect="light" visible-arrow>
                                <i class="icon-history f-18 cr-pointer" @click="verHistorial" />
                            </el-tooltip>
                        </div>
                    </div>
                    <!-- valor total -->
                    <div class="row my-3 mx-0">
                        <el-tooltip content="Σ etapas * ( 1 + % de utilidad general )" effect="light" visible-arrow placement="bottom">
                            <div class="indicador-g mx-auto">
                                <div class="my-auto">
                                    <p class="px-2 f-13"> Valor total de la cotización </p>
                                </div>
                                <div class="item">
                                    <span class="pr-1">{{ formatoMoneda(600000000)}}</span>
                                </div>
                            </div>
                        </el-tooltip>
                    </div>
                    <!-- etapas -->
                    <div class="row my-3 titulo-divisor mx-0">
                        <div class="col-auto my-auto d-middle-center">
                            <p class="f-600 f-14 ucfirst">Etapas</p>
                        </div>
                        <div class="col my-auto px-0">
                            <hr class="my-0 bg-white" />
                        </div>
                        <div class="col-auto my-auto">
                            <div class="d-middle icon-option">
                                <el-popover v-model="visibleAgregarEtapa" placement="bottom-end" width="360" trigger="click" class="px-0">
                                    <div class="row mx-0 justify-content-center f-600 text-general">
                                        Agregar etapa
                                    </div>
                                    <hr />
                                    <ValidationObserver ref="validatorAgregarEtapa">
                                        <div class="row mx-0 justify-content-center my-2">
                                            <div class="col-10 my-2">
                                                <p class="input-label-top">Etapas</p>
                                                <ValidationProvider v-slot="{errors}" name="etapa" rules="required">
                                                    <el-select v-model="valueSelect" placeholder="Seleccionar etapa" filterable size="small" class="w-100">
                                                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                                                    </el-select>
                                                    <vee-error :text="errors[0]" />
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </ValidationObserver>
                                    <hr />
                                    <div class="text-right m-0">
                                        <button class="btn btn-cerrar f-12" @click="visibleAgregarEtapa = !visibleAgregarEtapa">Cerrar</button>
                                        <button class="btn btn-crear f-12 ml-2" @click="handleAgregarEtapa">Agregar</button>
                                    </div>
                                    <el-tooltip slot="reference" content="Agregar etapa" effect="light" visible-arrow>
                                        <div class="wh-23 rounded-circle bg-5d icon-option-hover text-white cr-pointer mx-2">
                                            <i class="icon-plus" />
                                        </div>
                                    </el-tooltip>
                                </el-popover>
                                <el-tooltip content="Eliminar etapa" effect="light" visible-arrow>
                                    <div class="wh-23 rounded-circle bg-5d icon-option-hover text-white cr-pointer mx-2" @click="eliminarEtapaSeleccionada(idEtapaSelected)">
                                        <i class="icon-trash-can-outline" />
                                    </div>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <!-- listado etapas -->
                    <div class="row mx-0 my-3">
                        <div v-for="(data,idx) in etapas" :key="idx" class="col-auto mb-3" @click="seleccionarEtapa(data.id)">
                            <pill-etapa :nombre="data.nombre" :id_etapa="data.id" :class="`${idEtapaSelected == data.id ? 'selected':''}`" /> 
                        </div>
                    </div>
                    <div class="row mx-0 justify-content-center">
                        <div class="col-auto bg-a5 br-5 text-white f-12">
                            <p class=" py-2 px-3">
                                Valor final de la etapa:
                                <span class="f-600">3'000.000</span>
                            </p>
                        </div>
                    </div>
                    <!-- Listado de Configuraciones -->
                    <div class="row my-3 titulo-divisor mx-0">
                        <div class="col-auto my-auto d-middle-center">
                            <p class="f-600 f-14 ucfirst">Listado de Configuraciones</p>
                        </div>
                        <div class="col my-auto px-0">
                            <hr class="my-0 bg-white" />
                        </div>
                        <div class="col-auto my-auto">
                            <div class="d-middle icon-option">
                                <el-popover v-model="visibleAgregarConfiguracion" placement="bottom-end" width="360" trigger="click" class="px-0">
                                    <div class="row mx-0 justify-content-center f-600 text-general">
                                        Agregar configuración
                                    </div>
                                    <hr />
                                    <ValidationObserver ref="validatorAgregarConfiguracion">
                                        <div class="row mx-0 justify-content-center my-2">
                                            <div class="col-10 my-2">
                                                <p class="input-label-top">Configuraciones</p>
                                                <ValidationProvider v-slot="{errors}" name="configuración" rules="required">
                                                    <el-select v-model="valueSelect" placeholder="Seleccionar configuración" filterable size="small" class="w-100">
                                                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                                                    </el-select>
                                                    <vee-error :text="errors[0]" />
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </ValidationObserver>
                                    <hr />
                                    <div class="text-right m-0">
                                        <button class="btn btn-cerrar f-12" @click="visibleAgregarConfiguracion = !visibleAgregarConfiguracion">Cerrar</button>
                                        <button class="btn btn-crear f-12 ml-2" @click="handleAgregarEtapa">Agregar</button>
                                    </div>
                                    <el-tooltip slot="reference" content="Agregar configuracion" effect="light" visible-arrow>
                                        <div class="wh-23 rounded-circle bg-5d icon-option-hover text-white cr-pointer mx-2">
                                            <i class="icon-plus" />
                                        </div>
                                    </el-tooltip>
                                </el-popover>
                            </div>
                        </div>
                    </div>
                    <div class="row f-12 mx-3 text-general f-600 mb-2">
                        <div class="col-10">
                            <div class="row">
                                <div class="col-6">
                                    <p>Configuraciones</p>
                                </div>
                                <div class="col-2 text-center">
                                    <p>Valor</p>
                                </div>
                                <div class="col-2 text-center">
                                    <p>$ Utilidad</p>
                                </div>
                                <div class="col-2">
                                    <p class="d-middle">
                                        Valor final
                                        <el-popover placement="bottom" width="460" trigger="hover">
                                            <div>
                                                <p style="color:#B3B3B3">(Valor configuración) * (1 + % utilidad configuración + % utilidad país)</p>
                                            </div>
                                            <i slot="reference" class="icon-information-outline f-17" />
                                        </el-popover>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            <p>Actualizar</p>
                        </div>
                    </div>
                    <div v-for="(data, index) in 4" :key="index" class="row f-12 mx-3 text-5d my-3 cr-pointer">
                        <div class="col-10">
                            <div class="row  border rounded-pill" @click.stop="verConfiguracion">
                                <div class="col-6 my-auto">
                                    <p class="">Planta láctea de 23.000 litros</p>
                                </div>
                                <div class="col-2 text-center my-auto">
                                    <p class="">500.000</p>
                                </div>
                                <div class="col-2 text-center my-auto">
                                    <p class="">1%</p>
                                </div>
                                <div class="col-2 d-middle justify-content-around">
                                    <p class="f-600">500.000</p>
                                    <i class="icon-close-circle f-20 cr-pointer text-a5" @click.stop="deleteConfiguration" />
                                </div>
                            </div>
                        </div>
                        <div class="col-2 text-center">
                            <div class="btn-square32-5d ">
                                <i class="icon-restore f-18 text-white cr-pointer" @click="updateConfiguration" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- partials -->
        <modal-eliminar-cotizacion-cliente ref="abrirModalEliminarCotizacion2" />
        <modal-eliminar-etapa-seleccionada ref="abrirModalEliminarEtapaSeleccionada" />
        <modal-eliminar-configuracion ref="abrirModalEliminarConfigurar" />
        <modal-editar-info-cotizacion-cliente ref="openModalEditarCotizacionInfo" />
        <modal-historial-cambios-porcentaje ref="openModalHistorialCambios" />
        <modal-eliminar ref="abrirModalEliminarCotizacion" title="Eliminar cotizacion" mensaje="¿Está seguro que desea eliminar esta cotización?" mensaje2="" @delete="handleEliminarCotizacion" />
    </section>
</template>

<script>
import modalEliminarCotizacionCliente from './partials/modalEliminarCotizacionCliente'
import modalEditarInfoCotizacionCliente from './partials/modalEditarInfoCotizacionCliente'
import modalEliminarEtapaSeleccionada from './partials/modalEliminarEtapaSeleccionada'
import modalEliminarConfiguracion from './partials/modalEliminarConfiguracion'
import modalHistorialCambiosPorcentaje from './partials/modalHistorialCambiosPorcentaje'
import pillEtapa from './components/pillEtapa'
export default {
    components: {
        modalEliminarCotizacionCliente,
        modalEliminarEtapaSeleccionada,
        modalEliminarConfiguracion,
        modalEditarInfoCotizacionCliente,
        modalHistorialCambiosPorcentaje,
        pillEtapa,
    },
    data() {
        return {
            visibleAgregarEtapa: false,
            visibleAgregarConfiguracion: false,
            visibleEditarPorcentaje: false,
            etapaName: '',
            etapas:[
                {
                    id: 1,
                    nombre: 'Etapa 1'
                },
                {
                    id: 2,
                    nombre: 'Etapa 2'
                },
                {
                    id: 3,
                    nombre: 'Etapa 3'
                },
                {
                    id: 4,
                    nombre: 'Etapa 4'
                },
                {
                    id: 5,
                    nombre: 'Etapa 5 con muchas configuraciones para hacer prueba de css'
                },
            ],
            idEtapaSelected: null,
            options: [
                {
                    value: 'Option1',
                    label: 'Option1'
                }, 
                {
                    value: 'Option2',
                    label: 'Option2'
                }, 
                {
                    value: 'Option3',
                    label: 'Option3'
                }, 
                {
                    value: 'Option4',
                    label: 'Option4'
                }, 
                {
                    value: 'Option5',
                    label: 'Option5'
                }
            ],
            valueSelect: '',
            valorPorcentajeGeneral: '',
        }
    },
    methods: {
        eliminarCotizacion() {
            //this.$refs.abrirModalEliminarCotizacion.toggle() // modal eliminar
            this.$refs.abrirModalEliminarCotizacion.toggle() // modal en partials
        },
        handleEliminarCotizacion() {
            console.log('a eliminar la cotizacion cliente');
        },
        editarCotizacion() {
            this.$refs.openModalEditarCotizacionInfo.toggle()
        },
        async handleAgregarEtapa() {
            const valid = await this.$refs.validatorAgregarEtapa.validate();
            if (valid) {
                // action agregar etapa
                this.visibleAgregarEtapa = !this.visibleAgregarEtapa
            }
            console.log('add etapa');
        },
        seleccionarEtapa(id_etapa){
            this.idEtapaSelected = id_etapa
            // action listar configuraciones
        },
        eliminarEtapaSeleccionada(id_etapa){
            if (this.idEtapaSelected == null) {
                this.$notify({title: 'Seleccionar una etapa', type:'warning'})
            } else {
                this.$refs.abrirModalEliminarEtapaSeleccionada.toggle()
                console.log('id_etapa',id_etapa);
                // action eliminar etapa seleccionada
            }
        },
        async handleAgregarConfiguracion() {
            const valid = await this.$refs.validatorAgregarConfiguracion.validate();
            if (valid) {
                // action agregar etapa
                this.visibleAgregarConfiguracion = !this.visibleAgregarConfiguracion
            }
            console.log('add etapa');
        },
        deleteConfiguration(){
            this.$refs.abrirModalEliminarConfigurar.toggle()
        },
        updateConfiguration(){
            console.log('update configuration info');
        },
        verHistorial(){
            this.$refs.openModalHistorialCambios.toggle()
            console.log('verHistorial');
        },
        verConfiguracion(){
            this.$router.push({ name: 'cotizaciones.ver.cliente.configuracion.materiales' })
        },
        handleEditarPorcentaje(){
            this.visibleEditarPorcentaje = false
        }
    },
}
</script>

<style lang="scss" scoped>
.ver-cotizacion-cliente{
    background: white;
    .base-indicador{
        border-radius: 4px;
        height: 36px;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }
    .base-indicador-item{
        border-radius: 0px 1px 1px 0px;
        padding: 0px 10px;
        display: flex;
        align-items: center;
        color: #fff;
    }
    .indicador-g{
        border: 1px solid var(--color-general);
        color: var(--color-general);
        @extend .base-indicador;
        .item{
            background: var(--color-general);
            @extend .base-indicador-item;
        }
    }
    .w-75px{
        width: 75px;
    }
    .bg-a5{
        background: #A5A5A5;
    }
    .text-a5{
        color: #A5A5A5;
    }
  
}
</style>